import * as React from 'react';
import NumberFormat from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  format?: string | ((input: string) => string);
}

const MaskedNumber = React.forwardRef<NumberFormat<string>, CustomProps>(
  function MaskedNumber(props, ref) {
    const { onChange, format, ...other } = props;
    return (
      <NumberFormat
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format={
          format ||
          ((input: string) => {
            // format the numbers as 1 (###) ###-#### while allowing the user to type 1 at the beginning or adding it as they type
            const [, cg1, cg2, cg3, cg4] =
              input.match(
                /(1)?(?: \()?(\d{0,3})?(?:\) )?(\d{0,3})?(?:-)?(\d{0,4})?/
              ) ?? [];
            const group1 = cg2?.padEnd(3, ' ') ?? '   ';
            const group2 = cg3?.padEnd(3, ' ') ?? '   ';
            return `${cg1 ?? '1'} (${group1}) ${group2}-${cg4 ?? ''}`;
          })
        }
        isNumericString
      />
    );
  }
);

export default MaskedNumber;
