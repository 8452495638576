import React from 'react';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import Navbar from '../navbar/navbar.component';

const Layout = (): JSX.Element => {
  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Navbar showUserMenu={false} />
      <Outlet />
    </Box>
  );
};

export default Layout;
