import React, { FC } from 'react';
import SelectorBody from './components/selector-body.component';
import SorensonLogin from 'shared/assets/images/sorenson-login-new.png';
import SelectorHeader from './components/selector-header.component';
import Navbar from 'shared/components/navbar/navbar.component';
import CardContainer from 'shared/components/card/card.component';
import Page from 'shared/components/page/page.component';

const LoginSelectorPage: FC = () => {
  return (
    <Page bgImage={SorensonLogin} title='Sign In Selector'>
      <Navbar showUserMenu={false} />
      <CardContainer>
        <SelectorHeader />
        <SelectorBody />
      </CardContainer>
    </Page>
  );
};

export default LoginSelectorPage;
