import { useState } from 'react';
import { useAuth } from 'shared/contexts/auth-context';
import axios, { AxiosResponse } from 'axios';
import { useAlert } from 'shared/contexts/alert.context';
import { getPlatform, getRequestBody, getSorensonEndpoint } from '../helpers';
import { STRINGS, UserType } from 'shared/constants';
interface IFormInput {
  link: string;
  phone: string;
  conferenceId: string;
  passcode?: string;
  language?: string;
}

const useRequestInterpreter = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [callId, setCallId] = useState<string>();

  const { sorensonUser, ip, getTokenSilently, location } = useAuth();
  const { showAlert } = useAlert();

  const submit = async ({
    link,
    phone,
    conferenceId,
    language,
    passcode,
  }: IFormInput) => {
    try {
      setLoading(true);
      const platform = getPlatform(link);
      const apiKey =
        sorensonUser.userType === UserType.VRI
          ? process.env.REACT_APP_API_KEY_VRI
          : process.env.REACT_APP_API_KEY_VRS;
      let billingId;
      let billingName;

      if (sorensonUser.userType === UserType.VRI) {
        const token = await getTokenSilently();
        const { data } = await axios.post(
          `${process.env.REACT_APP_BILLING_API_BASE_URL}/GetUserBillingInformation`,
          { contact_email: sorensonUser.email },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        billingId = data.BillingId;
        billingName = data.BillingName;
      }
      const { data } = await axios.post(
        getSorensonEndpoint(platform, sorensonUser.userType),
        getRequestBody(
          link,
          sorensonUser,
          ip,
          conferenceId,
          platform,
          await getTokenSilently(),
          location,
          phone,
          language,
          billingName,
          billingId,
          passcode
        ),
        {
          headers: {
            'x-api-key': apiKey as string,
            'Content-Type': 'application/json',
          },
        }
      );
      if (data.callId) {
        setCallId(data);
      }
    } catch ({ response }: any) {
      let errorMessage = STRINGS.ERROR_STRINGS.UNABLE_TO_REQUEST;
      if ((response as AxiosResponse).data) {
        errorMessage =
          (response as AxiosResponse).data.message ===
          STRINGS.RELAY_RESPONSES.DUPLICATE_REQUEST
            ? STRINGS.ERROR_STRINGS.DUPLICATE_REQUEST
            : errorMessage;
      }
      showAlert('error', errorMessage);
    } finally {
      setLoading(false);
    }
  };
  return { submit, loading, callId };
};

export default useRequestInterpreter;

export type { IFormInput };
