import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';
import { PrivateLayout, PublicLayout } from 'shared/components/layout';
import useTheme from 'shared/contexts/theme-mode.context';

import { Login, LoginSelector } from 'modules/authentication';
import { RequestInterpreter, ThankYouPage } from 'modules/interpreter';
import SnackbarAlert from 'shared/components/snackbarAlert/SnackbarAlert';

import NotFound from 'shared/components/not-found/not-found.component';

import { AnimatePresence } from 'framer-motion';
import RequestLocation from 'modules/interpreter/request-location/request-location.page';

export default function AppRouter() {
  const { theme } = useTheme();
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert />
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path='/' element={<PrivateLayout />}>
            <Route index element={<RequestInterpreter />} />
            <Route path='/location' element={<RequestLocation />} />
            <Route path='/thank-you' element={<ThankYouPage />} />
            <Route path='*' element={<NotFound />} />
          </Route>
          <Route path='/sign-in' element={<PublicLayout />}>
            <Route index element={<LoginSelector />} />
            <Route path='vrs' element={<Login />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </ThemeProvider>
  );
}
